<template>
  <b-modal id="modal-item" hide-header hide-footer ok-only ok-title="Accept" centered size="lg" title="Large Modal"
    class="custom__modal--large" @hide="clearForm" @show="showModal">
    <div class="section">
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div style="cursor: pointer;" @click="directPage">
              <feather-icon size="24" class="text-dark font-weight-bolder" icon="ArrowLeftIcon" />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
            </div>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-center align-items-center">
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Informasi Kategori 2
            </h1>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-button class="px-3" @click="editUuid == null ? createItem() : updateItem()">
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col cols="12" md="5">
            <b-row>
              <b-col cols="12" class="text-center mb-2">
                <UploadPhoto v-if="!photo_url" :label-for="'upload_photo'"
                  :style-name="'width:140px;height:140px;margin: 0 auto;'" :photo-url-parent="photo_url"
                  @photo="getPhoto" />
                <div v-else>
                  <div class="box__upload--photo mx-auto" :style="`background-image: url(${photo_url});`"
                    @click="photo_url = ''">
                    <b-avatar class="floating__close--button">
                      <feather-icon class="text-danger" icon="XIcon" />
                    </b-avatar>
                  </div>
                </div>
              </b-col>
              <!-- Nama Kategori -->
              <!-- <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-nama-barang">Nama Kategori 2<span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input id="v-nama-barang" v-model="formPayload.name" type="text" placeholder="Nama Kategori"
                    class="custom__input" />
                </b-form-group>
              </b-col> -->
              <!-- Parent Kategori -->
              <!-- <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="category_1">Parent Kategori<span class="text-danger">*</span></label>
                <b-form-group>
                  <v-select id="category_1" v-model="formPayload.parent_uuid" :options="optionParent" label="name"
                    placeholder="Type to search parent category..." @search="onSearchParent"
                    :reduce="option => option.uuid">
                    <template slot="no-options">
                      Type here to search parent category...
                    </template>
</v-select>
</b-form-group>
</b-col> -->
              <!-- Kategori 1 -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="category-1">Nama Kategori 1<span class="text-danger">*</span></label>
                <b-form-group>
                  <v-select id="category-1" v-model="formPayload.parent_uuid" :options="optionParent" label="name"
                    placeholder="Type to search category 1..." @search="onSearchParent">
                    <template slot="no-options">
                      Type here to search category 1...
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <!-- Sub Kategori 2 -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="category-2">Nama Kategori 2<span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input id="category-2" v-model="formPayload.name" type="text" placeholder="Nama Kategori 2"
                    class="custom__input" />
                  <!-- <v-select id="category_1" v-model="child_category" :options="optionChildCategory1" label="name"
                    placeholder="Type to search sub category 2..." @search="onSearchChildCategory"
                    :disabled="!parent_category">
                    <template slot="no-options">
                      Type here to search sub category 2...
                    </template>
                  </v-select> -->
                </b-form-group>
              </b-col>
              <!-- Sub Kategori 3 -->
              <!-- <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="sub_category_3">Sub Kategori 3<span class="text-danger">*</span></label>
                <b-form-group>
                  <v-select id="category_1" v-model="formPayload.item_uuid" :options="optionChildCategory2" label="name"
                    placeholder="Type to search sub category 3..." @search="onSearchChildCategory2"
                    :disabled="!child_category" :reduce="value => value.uuid">
                    <template slot="no-options">
                      Type here to search sub category 3...
                    </template>
                  </v-select>
                </b-form-group>
              </b-col> -->
              <!-- Deskripsi Kategori -->
              <b-col cols="12" md="12" class="mb-3 custom__form--input">
                <label for="v-deskripsi">Deskripsi Kategori 2</label>
                <b-form-group>
                  <b-form-textarea id="v-deskripsi" v-model="formPayload.description" rows="5"
                    placeholder="Deskripsi Kategori 2" class="custom__textarea" />
                </b-form-group>
                <b-card class="px-33 py-1 mt-3" style="background:#FFF4EC;border-radius:8px;" no-body>
                  <div class="d-flex align-items-center">
                    <div>
                      <div style="width:30px;height:30px;background: #FF8F39;opacity:0.75;border-radius:50%;" />
                    </div>
                    <span class="text-dark fw-bold-500 size14 ml-1">
                      Informasi disini merupakan penjelasan secara umum, atau peruntukan suatu barang, tidak menjelaskan
                      spesifik sku dan merk tertentu.
                    </span>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea, BCard, BAvatar,
} from 'bootstrap-vue'

import UploadPhoto from '@/components/UploadPhoto.vue'

import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    BCard,
    UploadPhoto,
    BAvatar,
    vSelect
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    iconBank: {
      type: String,
    },
  },
  watch: {
    iconBank(value) {
      this.photo_url = value
    },
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
  },
  data() {
    return {
      formPayload: {
        name: '',
        description: '',
        logo: '',
        parent_uuid: null
      },
      photo_url: '',
      editUuid: null,
      result: {},
      parent_category: null,
      child_category: null,
      child_category2: null,
      optionParent: [],
      optionChildCategory1: [],
      optionChildCategory2: []
    }
  },
  mounted() {

  },
  methods: {
    showModal() {
      this.getData()
    },
    clearForm() {
      this.formPayload = {
        name: '',
        description: '',
        logo: '',
        parent_uuid: null
      }
    },
    onSearchParent(search, loading) {
      if (search.length) {
        loading(true)
        this.searchParent(loading, search, this)
      }
    },
    searchParent: _.debounce((loading, search, vm) => {
      vm.$http.get(`/item?search=${encodeURI(search)}&category_type=category_1`)
        .then(response => {
          vm.optionParent = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    async getData(page = 1) {
      this.isLoading = true
      const queryParams = {}
      queryParams.page = page
      queryParams.category_type = 'category_1'

      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.isLoading = false
        this.result = result.data
        this.optionParent = result.data.data.data.map(obj => ({
          uuid: obj.uuid,
          name: obj.name,
        }))
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-item')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 140px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
